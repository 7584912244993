
// Body
$body-bg: #fff;

// Typography
$font-family-sans-serif: "Nunito", sans-serif;
$font-size-base: 0.9rem;
$line-height-base: 1.6;

// Colors
$gray: #262F38;
$lightGray: #f5f5f5;
$darkGray: #252934;
$blue: #3490dc;
$red: #e3342f;
$orange: #FFA500;
$yellow: #ffff00;
